<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高級搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="年月期间"
                    label-for="purchase_team_id"
                    label-size="sm"
                    class="mb-1"
                >
                  <el-date-picker
                      v-model="condition.time"
                      type="month"
                      placeholder="选择日期"
                      format="yyyy 年 MM 月 "
                      value-format="timestamp"
                      :picker-options="pickerOption"
                  >
                  </el-date-picker>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="品牌"
                    label-for="brand_id"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      :options="getCodeOptions('brand')"
                      v-model="condition.brand_id"
                      name="purchase_team_id"
                      class="select-size-sm"
                      placeholder="请选择品牌"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售权"
                    label-for="purchase_team_id"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      :options="getCodeOptions('purchase_team')"
                      v-model="condition.purchase_team_id"
                      name="purchase_team_id"
                      class="select-size-sm"
                      placeholder="请选择销售权团队"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        hover
        small
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(purchase_team_id)="{value}">
          {{getCodeLabel('purchase_team', value)}}
        </template>
        <template #cell(brand_name)="data">
          {{isEmpty(data.item.ext.brand_name)?"":data.item.ext.brand_name}}
        </template>
        <template #cell(ba_year)="data">
          {{data.item.ba_year + "-" + data.item.ba_month}}
        </template>
        <template #cell(use_amount)="data">
          {{isEmpty(data.item.ext.use_amount)?0:data.item.ext.use_amount}}
        </template>
        <template #cell(write_amount)="data">
          {{isEmpty(data.item.ext.write_amount)?0:data.item.ext.write_amount}}
        </template>

        <!--采购调整额度-->
        <template #cell(amount_change)="data">
          <div style="width: 60px">
            <b-form-input
                @change="updateCharge(data.item)"
                size="sm"
                type="number"
                v-model="data.item.amount_change"
            />
          </div>
        </template>

        <!--采购调整后额度-->
        <template #cell(amount_changed)="data">
          {{addNumber(data.item.amount30,data.item.amount_change)}}
        </template>
        <!--剩余额度-->
        <template #cell(residue_amount)="data">
          {{addNumber(data.item.amount30,data.item.amount_change)-isNumber(data.item.ext.use_amount)}}
        </template>

        <!-- Columns -->
        <template #cell(id)="data">
            #{{ data.item.id }}
        </template>

        <!-- Column: Type -->
        <template #cell(${classVar}_type)="data">
          {{getCodeLabel('brandamount_type', data.item.brandamount_type)}}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-$ {getCodeColor('state', data.item.state)}`"

          >
            {{getCodeLabel('state', data.item.state)}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
<!--单菜单-->
          <b-link>
            <feather-icon @click="delOrderItem(data.item)"
                size="16"
                class="align-middle text-body"
            />
          </b-link>
<!--多菜单-->
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-brandamount-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-brandamount-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty,
  addNumber,
  isNumber,
  Number
} from '@core/utils/filter'
import brandamountUseList from './brandamountUseList'
import brandamountStore from './brandamountStore'
import Ripple from "vue-ripple-directive";
import modalSelect from "@/views/components/modal/ModalSelect";
import ElDatePicker from "element-ui/packages/date-picker";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    modalSelect,
    ElDatePicker,
  },
  data() {
    return {
      isShowCard: false,
      rangeConfig: {
        maxDate: 'today',
        dateFormat: 'Y-m'
      },
      user: {},
      pickerOption: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        }
      },
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('brandamount/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  setup() {
    // Register module
    if (!store.hasModule('brandamount')) store.registerModule('brandamount', brandamountStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('brandamount')) store.unregisterModule('brandamount')
    })

    const advanced_search = function(){
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function(){
      refetchData()
    }

    const resetCondition = function(){
      this.condition = {}
      refetchData()
    }

    const changeSelect = function (key,param){
      if(!isEmpty(param)){
        this.condition[key] = param.value
      }else {
        this.condition[key] = null
      }
      this.$forceUpdate()
    }

    const fromChildren = function (params, modal, item) {
      for (let i = 0; i < modal.length; i++) {
        item[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const updateCharge = function(params){
      store.dispatch("brandamount/save",params).then(res =>{
        refetchData()
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = brandamountUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      advanced_search,
      searchByCondition,
      resetCondition,
      changeSelect,
      fromChildren,
      condition,
      updateCharge,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      addNumber,
      isNumber,
      Number,
      // UI
     toTime,
     toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
